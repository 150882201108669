.rpv-core__inner-page {
  background-color: transparent;
}

.rpv-core__textbox {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0;
  color: white;
  height: 1.5rem;
  padding: 0;
  text-align: center;
  width: 1.5rem;
}